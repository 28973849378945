.compliance-calender-table-container {
  padding: 10px;
}

.compliance-calender-select-month-container-header {
  display: flex;
  justify-content: space-between;
}

.compliance-calender-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.compliance-calender-table {
  width: 100%;
  border-collapse: collapse;
}

.compliance-calender-table,
.compliance-calender-table-thead {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}

.compliance-calender-table-td {
  width: 100px;
}

.compliance-calender-table-th {
  background-color: #B22222;
  color: #ffffff;
}

.compliance-calender-month-container-header-select-container {
  display: flex;
  gap: 10px;
}

.compliance-calender-dialog-header-title {
  color: #B22222;
}

.compliance-calender-no-data-found {
  padding: 16px;
}